import React from 'react'
import './footer.css'
export default function Footer() {
    return (
        <div className="footer">
            <p>© 2020 Gagan Chaudhary. All rights reserved.</p>
        </div>
       
    )
}
